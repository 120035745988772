<template>
  <v-container v-bind:class="{'pa-0': $vuetify.breakpoint.smAndDown}">

    <v-card>
      <v-card-title>
        <v-icon left>mdi-finance</v-icon>
        Controles

        <v-spacer></v-spacer>

        <v-btn color="success" @click="openNew">
          <v-icon left>mdi-plus</v-icon>
          Nuevo
        </v-btn>
      </v-card-title>
      <v-divider/>



        <v-card-text class="pa-0">
        <v-skeleton-loader
          :type="$props.user?'list-item-two-line':'list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line,list-item-two-line'"
          class="fill-height mx-4 mt-4" v-if="loading2">
        </v-skeleton-loader>

        <!-- 
                 <v-simple-table v-if="exerciseListData.length>0">

                    <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <v-icon left>mdi-calendar</v-icon>
                                Fecha</th>
                              <th class="text-left">
                                <v-icon left>
                                    mdi-weight-kilogram
                                </v-icon>
                                Peso</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(record, i) in exerciseListData" :key="i">
                              <td>{{ record.lastUpdateDate? formatServerTimestamp(record.lastUpdateDate):'---' }}</td>
                              <td >{{ record.name }}</td>
                            </tr>
                          </tbody>
                        </template>

</v-simple-table> -->

        <v-alert class="mx-4 my-2" type="info" v-if="exerciseList.length == 0 && !loading2" text>

         
          No hay controles registrados

          

        </v-alert>


        <v-list class="pa-0" dense>

          <template v-for="(exercise, index) in exerciseList">
          <v-list-item two-line  @click="showExercise(exercise)" :key="index"   v-if="exercise.name.toLowerCase().includes(searchQuery.toLowerCase())">
              <v-list-item-avatar :color="returnColor(exercise.type)" class="rounded-lg">
                <v-icon v-if="exercise.type == 0">
                  mdi-weight-kilogram
                </v-icon>
                <v-icon v-else-if="exercise.type == 1">
                  mdi-counter
                </v-icon>
                <v-icon v-else-if="exercise.type == 2 || exercise.type == 5">
                  mdi-clock-time-four-outline
                </v-icon>
                <v-icon v-else-if="exercise.type == 3">
                  mdi-ruler
                </v-icon>
                <v-icon v-else>
                  mdi-weight-kilogram
                </v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ exercise.name.replace('mxs', 'm/s') }}</v-list-item-title>
                <v-list-item-subtitle>
                  <v-icon x-small style="color:lightgray;">
                    mdi-history
                  </v-icon>
                  {{ returnMomentFormat(exercise.lastUpdateDate) }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-icon>mdi-chevron-right</v-icon>
              </v-list-item-action>

             
          </v-list-item>
          <v-divider  :key="index+'divid'"></v-divider>
          </template>


          <v-divider/>
          <v-list-item @click.stop="openNew()">
            <v-list-item-avatar color="success" class="rounded-lg">
              <v-icon>
                mdi-plus
              </v-icon>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>
                Agregar nuevo control
              </v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-icon>mdi-chevron-right</v-icon>
            </v-list-item-action>
          </v-list-item>
        </v-list>

       


        <v-bottom-sheet v-model="picker" max-width="500">
          <v-card>
           <!--  <v-card-title class="mb-4" style="font-size: 1.1rem;">
              
              {{ exercise && exercise.name ? exercise.name.replace('mxs', 'm/s') : 'ERROR' }}
              <v-spacer></v-spacer>
              <v-btn icon @click="picker = false" elevation="0">
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </v-card-title> -->

            <v-toolbar class="pl-4 mb-6" flat>


                <v-avatar size="35" :color="returnColor(exercise.type)" class="mr-4 rounded-lg">
                  <v-icon v-if="exercise.type == 0">
                    mdi-weight-kilogram
                  </v-icon>
                  <v-icon v-else-if="exercise.type == 1">
                    mdi-counter
                  </v-icon>
                  <v-icon v-else-if="exercise.type == 2 || exercise.type == 5">
                    mdi-clock-time-four-outline
                  </v-icon>
                  <v-icon v-else-if="exercise.type == 3">
                    mdi-ruler
                  </v-icon>
                  <v-icon v-else>
                    mdi-weight-kilogram
                  </v-icon>
                </v-avatar>
              
              
              
              
               {{ exercise && exercise.name ? exercise.name.replace('mxs', 'm/s') : 'ERROR' }}
              <v-spacer></v-spacer>
              <v-btn icon @click="picker = false" elevation="0">
                <v-icon>mdi-close</v-icon>
              </v-btn>

            </v-toolbar>



            <v-card-text>

              <v-row>
                <v-col>
                  <v-btn block rounded-lg large color="primary darken-1" @click="showHistory">
                    <v-icon class="mr-4">mdi-history</v-icon>
                    Ver Historial
                  </v-btn>
                  <v-divider class="my-5"></v-divider>
                  <v-btn rounded-lg block large color="success" @click="addNewShow" class="mb-4">
                    <v-icon left>mdi-plus</v-icon>
                    Agregar Nuevo Ajuste
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="history">
          <v-card class="rounded-0">

            <v-toolbar flat class="pl-4">

              <v-avatar size="35" :color="returnColor(exercise.type)" class="mr-4 rounded-lg">
                <v-icon v-if="exercise.type == 0">
                  mdi-weight-kilogram
                </v-icon>
                <v-icon v-else-if="exercise.type == 1">
                  mdi-counter
                </v-icon>
                <v-icon v-else-if="exercise.type == 2 || exercise.type == 5">
                  mdi-clock-time-four-outline
                </v-icon>
                <v-icon v-else-if="exercise.type == 3">
                  mdi-ruler
                </v-icon>
                <v-icon v-else>
                  mdi-weight-kilogram
                </v-icon>
              </v-avatar>
              {{ exercise && exercise.name ? exercise.name.replace('mxs', 'm/s') : 'ERROR' }}
              <v-spacer></v-spacer>
              <v-btn fab x-small @click="deleteExercise" elevation="0">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </v-toolbar>


            <v-card-text class="px-0">
              <v-skeleton-loader type="image" width="100%" v-if="loading"></v-skeleton-loader>

              <template v-else>
                <div v-for="(exercise, index) in filteredExercises" :key="index">
                  <div v-if="exercise.history && exercise.history.length">
                     <v-tabs grow>
                      <v-tab v-show="checkCurrentWeightValues() && exercise.name != 'Peso corporal'">
                        MAX-RM
                      </v-tab>
                      <v-tab v-show="checkCurrentWeightValues() && exercise.name != 'Peso corporal'">
                        Progreso
                      </v-tab>


                      <v-tab-item class="px-2">
 <VueApexCharts v-if="!loading" height="300" type="bar" :options="chartOptions" class="mt-2"
                        v-show="checkCurrentWeightValues() && exercise.name != 'Peso corporal'" :series="chartSeries">
                      </VueApexCharts>

                      <VueApexCharts v-if="!loading" height="300" type="line" :options="chartTimeSeriesOptions"
                        v-show="checkTimeValues() || checkVelocityValues() || checkDistanceValues() || exercise.name == 'Peso corporal'"
                        :series="chartTimeSeries"></VueApexCharts>

                          <v-simple-table fixed-header :height="returnHeight">
                        <template v-slot:default>
                          <thead>
                            <tr>
                              <th class="text-left">
                                <v-icon left>mdi-calendar</v-icon>
                                Fecha
                              </th>
                              <th class="text-left"
                                v-show="checkCurrentWeightValues() || exercise.name == 'Peso corporal'">
                                <v-icon left>
                                  mdi-weight-kilogram
                                </v-icon>
                                Peso
                              </th>
                              <th class="text-left"
                                v-show="checkCurrentWeightValues() && exercise.name != 'Peso corporal'">
                                <v-icon left>
                                  mdi-counter
                                </v-icon>

                                Reps
                              </th>
                              <th class="text-left" v-show="checkVelocityValues()">
                                <v-icon left>
                                  mdi-counter
                                </v-icon>
                                Vel(m/s)
                              </th>

                              <th class="text-left" v-show="checkTimeValues()">
                                <v-icon left>
                                  mdi-clock-time-four-outline
                                </v-icon>
                                Tiempo(seg)
                              </th>

                              <th class="text-left" v-show="checkDistanceValues()">
                                <v-icon>
                                  mdi-ruler
                                </v-icon>
                                Distancia(cm)
                              </th>



                              <th class="text-left">
                                <v-icon left>
                                  mdi-chart-bell-curve-cumulative
                                </v-icon>
                                Esfuerzo
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(record, i) in allExercises[0].history" :key="i" @click="rowClick(record)">
                              <td>{{ formatDate(record.date) }}</td>
                              <td v-show="record.weight">{{ record.weight }}</td>
                              <td v-show="record.repAmount && exercise.name != 'Peso corporal'">{{ record.repAmount }}
                              </td>
                              <td v-show="record.velocity">{{ record.velocity }}</td>
                              <td v-show="record.time">{{ record.time }}</td>
                              <td v-show="record.distance">{{ record.distance }}</td>

                              <td>
                                <v-btn :color="sliderColor(record.rpe)" dark x-small class="mr-2" fab elevation="0">
                                  {{ record.rpe }}
                                </v-btn>
                              </td>
                            </tr>
                          </tbody>
                        </template>
                      </v-simple-table>
                      </v-tab-item>
                       <v-tab-item style="overflow-y:scroll;height:55vh;overflow-x:hidden">
                        <v-row>
                         <v-col cols="12" md="3" v-for="(series, index) in repRangeProgressSeries" :key="index" class="rep-range-chart">
                          <v-divider></v-divider>
                              <h3 class="text-center">{{ series.name }}</h3>
                                                        <v-divider></v-divider>

                              <VueApexCharts 
                                type="line" 
                                :options="repRangeProgressChartOptions" 
                                :series="[series]" 
                                height="200" 
                              />
                            </v-col>

                            </v-row>
                      </v-tab-item>
                    
                     </v-tabs>

                 
                  </div>

                </div>
              </template>
              <v-card height="200px" color="grey darken-3 pa-4" elevation="0"
                v-if="!loading && filteredExercises && filteredExercises.length && filteredExercises[0].history.length < 1">
                <div class="text-h6 mb-4 mt-10 text-center">
                  No hay registros para este ejercicio
                </div>
                <v-btn @click="history = false" class="mb-4" text color="primary" block>
                  Volver al listado
                </v-btn>
              </v-card>
            </v-card-text>
            <v-card-actions class="pb-8">
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="history = false">Volver al listado</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>
        <v-bottom-sheet v-model="show" max-width="500">
          <v-card class="pb-6">
            <v-toolbar flat class="pl-4 mb-6">
              <v-avatar v-if="isNew" color="success"  class="mr-4 rounded-lg"  size="35">
                <v-icon v-if="isNew">
                  mdi-plus
                </v-icon>
              </v-avatar>
              <v-avatar v-else size="35" :color="returnColor(exercise.type)" class="mr-4 rounded-lg">

                

                <v-icon v-if="exercise.type == 0">
                  mdi-weight-kilogram
                </v-icon>
                <v-icon v-else-if="exercise.type == 1">
                  mdi-counter
                </v-icon>
                <v-icon v-else-if="exercise.type == 2 || exercise.type == 5">
                  mdi-clock-time-four-outline
                </v-icon>
                <v-icon v-else-if="exercise.type == 3">
                  mdi-ruler
                </v-icon>
                <v-icon v-else>
                  mdi-weight-kilogram
                </v-icon>
              </v-avatar>
             
              {{ exercise && exercise.name ? exercise.name.replace('mxs', 'm/s') : 'Nuevo Control' }}

              <v-spacer></v-spacer>
              <v-btn fab x-small @click="show = false" elevation="0" v-if="!update">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-btn fab x-small @click="deleteExerciseEntry" elevation="0" v-else>
                <v-icon>mdi-delete</v-icon>
              </v-btn>




            </v-toolbar>
            <v-card-text class="pb-0">
              <v-form ref="form" v-model="valid" lazy-validation>

                <v-btn-toggle v-model="exercise.type" mandatory class="mb-6"
                  v-if="isNew && exercise.name != 'Peso corporal' && !disableTypeButton">
                  <v-btn :disabled="disableTypeButton">
                    Carga
                  </v-btn>

                  <v-btn :disabled="disableTypeButton">
                    Velocidad
                  </v-btn>

                  <v-btn :disabled="disableTypeButton">
                    Tiempo
                  </v-btn>

                  <v-btn :disabled="disableTypeButton">
                    Dist
                  </v-btn>
                  <!--   <v-btn :disabled="disableTypeButton">
                            Tiempo(promedio/mejor)
                          </v-btn> -->
                </v-btn-toggle>


                <v-autocomplete :hide-no-data="!search" :search-input.sync="search" @change="updateExerciseData"
                  label="Seleccionar" outlined prepend-inner-icon="mdi-dumbbell" :items="availableExercises"
                  v-model="exercise.name" :rules="[rules.required]" required v-if="isNew" item-text="text"
                  item-value="name">
                  <template v-slot:no-data>
                    <v-list-item @click="addExercise(search)">
                      <v-list-item-content>
                        <v-list-item-title>
                          Agregar "<strong>{{ search }}</strong>".
                        </v-list-item-title>
                      </v-list-item-content>
                    </v-list-item>
                  </template>

                  <!-- <template v-slot:item="{ item }">
                        <v-list-item-content>
                          <v-list-item-title>
                            <v-icon left>
                              mdi-chevron-right
                            </v-icon>
                            {{ item.text? item.text : item }}
                          </v-list-item-title>
                        </v-list-item-content>
                        
                      </template> -->

                </v-autocomplete>


                <template v-if="!exercise.type || exercise.type == 0 || exercise.name == 'Peso corporal'">
                  <v-row no-gutters class="mb-4">
                    <v-col cols="6">
                      <v-subheader>
                        <v-icon left>
                          mdi-weight-kilogram
                        </v-icon>
                        {{ exercise.name == 'Peso corporal' ? "Peso" : "Carga" }} (kg)
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon fab small color="primary"
                        @click="exercise.currentWeight = Math.max(1, exercise.currentWeight - 1)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>

                    </v-col>
                    <v-col cols="2">
                      <v-text-field id="currentWeight" outlined dense hide-details v-model="exercise.currentWeight"
                        type="number" :rules="[rules.required]" required max="20" min="1"></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="ml-3" icon fab small color="primary" @click="exercise.currentWeight++">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>

                  <v-row no-gutters v-if="exercise.name != 'Peso corporal'">
                    <v-col cols="6">
                      <v-subheader>
                        <v-icon left>
                          mdi-counter
                        </v-icon>
                        Repeticiones
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon fab small color="primary"
                        @click="exercise.repAmount = Math.max(1, exercise.repAmount - 1)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>

                    </v-col>
                    <v-col cols="2">
                      <v-text-field outlined hide-details dense v-model="exercise.repAmount" type="number" max="20"
                        min="1" :rules="[rules.required]" required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="ml-3" icon fab small color="primary"
                        @click="exercise.repAmount < 20 ? exercise.repAmount++ : ''">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>


                </template>

                <template v-else-if="exercise.type == 1">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-subheader>
                        <v-icon left>
                          mdi-counter
                        </v-icon>
                        Velocidad (m/s)
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon fab small color="primary"
                        @click="exercise.velocity = Math.max(0, exercise.velocity - 1)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field outlined hide-details dense v-model="exercise.velocity" type="number" max="20"
                        min="1" :rules="[rules.required]" required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="ml-3" icon fab small color="primary" @click="exercise.velocity++">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>


                </template>
                <template v-else-if="exercise.type == 2 || exercise.type == 5">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-subheader>
                        <v-icon left>
                          mdi-clock-time-four-outline
                        </v-icon>
                        Tiempo promedio
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon fab small color="primary" @click="exercise.time = Math.max(0, exercise.time - 1)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field outlined hide-details dense v-model="exercise.time" type="number" max="20" min="1"
                        :rules="[rules.required]" required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="ml-3" icon fab small color="primary" @click="exercise.time++">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row no-gutters v-if="exercise.type == 5" class="mt-2">
                    <v-col cols="6">
                      <v-subheader>
                        <v-icon left>
                          mdi-clock-time-four-outline
                        </v-icon>
                        Mejor tiempo
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon fab small color="primary"
                        @click="exercise.bestTime = Math.max(0, exercise.bestTime - 1)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field outlined hide-details dense v-model="exercise.bestTime" type="number" max="20"
                        min="1" :rules="[rules.required]" required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="ml-3" icon fab small color="primary" @click="exercise.bestTime++">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>
                <template v-else-if="exercise.type == 3">
                  <v-row no-gutters>
                    <v-col cols="6">
                      <v-subheader>
                        <v-icon left>
                          mdi-ruler
                        </v-icon>
                        Distancia (cm)
                      </v-subheader>
                    </v-col>
                    <v-col cols="2">
                      <v-btn icon fab small color="primary"
                        @click="exercise.distance = Math.max(1, exercise.distance - 1)">
                        <v-icon>mdi-minus</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="2">
                      <v-text-field outlined hide-details dense v-model="exercise.distance" type="number" max="20"
                        min="1" :rules="[rules.required]" required></v-text-field>
                    </v-col>
                    <v-col cols="2">
                      <v-btn class="ml-3" icon fab small color="primary" @click="exercise.distance++">
                        <v-icon>mdi-plus</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </template>







                <div class="mb-8 mt-2" v-if="exercise.name != 'Peso corporal'">
                  <v-subheader>
                    <v-icon left>
                      mdi-chart-line
                    </v-icon>
                    Rango de Esfuerzo Percibido (RPE).<br /> (1 poco esfuerzo - 10 máximo esfuerzo)
                  </v-subheader>
                </div>
                <div class="d-flex align-center justify-space-between" v-if="exercise.name != 'Peso corporal'">
                  <v-btn icon fab color="primary" @click="exercise.rpe = Math.max(0, exercise.rpe - 1)">
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>


                  <v-slider :color="sliderColor(exercise.rpe)" hide-details :thumb-color="sliderColor(exercise.rpe)"
                    class=" mx-9" thumb-label="always" always-show filled v-model="exercise.rpe" :max="10"
                    :min="1"></v-slider>
                  <v-btn icon fab color="primary" @click="exercise.rpe++">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <v-divider class="my-4"></v-divider>


              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn color="error" text @click="show = false" :disabled="loading">
                <v-icon left>mdi-close</v-icon>
                Cancelar
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="success" @click="saveExerciseData" :loading="loading" v-if="update == false">
                <v-icon left>mdi-check</v-icon>
                Guardar
              </v-btn>
              <v-btn v-else color="success" @click="updateExerciseEntry" :loading="loading">
                <v-icon left>mdi-check</v-icon>
                Actualizar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-bottom-sheet>

        </v-card-text>
    </v-card>

  </v-container>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import moment from "moment";
import { v4 as uuidv4 } from "uuid";
import {
  getFirestore,
  collection,
  addDoc,
  getDocs,
  updateDoc,
  serverTimestamp,
  Timestamp,
  listc,
  doc,
  setDoc,
  deleteDoc,
  query,
  where,
} from "firebase/firestore";

import WeightChart from "./WeightChart.vue";

const db = getFirestore();

export default {
  props: {
    user: {
      type: Object,
      required: false,
      default: null,
    },
  },
  components: {
    "WeightChart": WeightChart,
    "VueApexCharts": VueApexCharts,
  },
  mounted() {
    this.$vuetify.goTo(0);
    this.loadExerciseList();
  },
  data() {

    return {
      repRangeProgressSeries: [],  // Custom series for rep range progress
      repRangeProgressChartOptions: {  // Custom chart options
      colors: ['#2196f3', '#6ee9ff'],
        stroke: {
          curve: 'smooth',
          width: 4
        },
        chart: {
          type: 'line',
          foreColor: '#ccc',

          height: 350,
           toolbar: {
            show: false,
          },
        },
        title: {
          text: 'Progreso',
        },
        xaxis: {
          type: 'datetime',
          title: {
            text: 'Fecha',
          },
        },
        yaxis: {
          title: {
            text: 'Peso (kg)',
          },
        },
        tooltip: {
          theme: 'light'
        },
        grid: {
          borderColor: '#ffffff30'
        }
      },
      update: false,
      disableTypeButton: false,
      search: null,
      exercise: {
        repAmount: 10,
        rpe: 5,
        type: 0,
        velocity: null,
        currentWeight: null,
        name: null,
        time: null,
        bestTime: null,
        distance: null
      },
      show: false,
      picker: false,
      history: false,
      loading: false,
      loading2: false,
      valid: true,
      isNew: false,
      rules: {
        required: (value) => !!value || "Requerido",
      },
      searchQuery: "",
      allExercises: [],
      exerciseList: [
      ],
      exerciseListData: [],
      allExercisesList: [
        {
          text: 'Peso corporal',
          name: 'Peso corporal',
          type: 4
        },
        'Press de banca', 'Press militar con barra', 'Press militar con mancuernas',
        'Press de banca inclinado', 'Press de banca con mancuernas',
        'Sentadilla', 'Sentadilla frontal', 'Sentadilla copa',
        'Peso muerto con barra hexagonal', 'Peso muerto rumano', 'Peso muerto sumo', 'Peso muerto convencional',
        'Hip Thrust a una pierna', 'Hip Thrust a una pierna con barra', 'Hip Thrust con barra', 'Hip Thrust con mancuerna',
        'Remo serrucho', 'Remo horizontal con barra',
        'Dominadas', 'Dominadas con banda', 'Dominadas con agarre neutro', 'Dominadas con agarre supino', 'Dominadas con agarre prono',

        {
          text: 'Bicicleta (500mts)',
          name: 'Bicicleta (500mts)',
          type: 2
        },
        {
          text: 'Test 5',
          name: 'Test 5',
          type: 5
        },
        {
          text: 'Test 10',
          name: 'Test 10',
          type: 5
        },
        {
          text: 'Test 15',
          name: 'Test 15',
          type: 5
        },
        {
          text: 'Salto vertical (cm)',
          name: 'Salto vertical (cm)',
          type: 3
        },
        {
          text: 'Salto horizontal (cm)',
          name: 'Salto horizontal (cm)',
          type: 3
        },
        {
          text: 'Salto unipodal vertical (cm)',
          name: 'Salto unipodal vertical (cm)',
          type: 3
        },
        {
          text: 'Salto unipodal horizontal (cm)',
          name: 'Salto unipodal horizontal (cm)',
          type: 3
        },
        {
          text: 'Salto vertical (m/s)',
          name: 'Salto vertical (mxs)',
          type: 1
        },
        {
          text: 'Salto horizontal (m/s)',
          name: 'Salto horizontal (mxs)',
          type: 1
        },
        {
          text: 'Salto unipodal vertical (m/s)',
          name: 'Salto unipodal vertical (mxs)',
          type: 1
        },
        {
          text: 'Salto unipodal horizontal (m/s)',
          name: 'Salto unipodal horizontal (mxs)',
          type: 1
        },
        {
          text: 'Test 150',
          name: 'Test 150',
          type: 3
        },
        {
          text: 'Bicicleta Max Watts (5seg)',
          name: 'Bicicleta Max Watts (5seg)',
          type: 2
        },
      ],
      availableExercises: [],
      selectedExerciseNames: [],
      selectedExercises: [],
      weightsByRepObjArr: {},
      chartOptions: {


        title: {
          text: '',
          align: 'center',
          style: {
            fontSize: '13px',
          }
        },
        chart: {
          foreColor: '#ccc',
          toolbar: {
            show: false,
          },
        },
        xaxis: {
          title: {
            text: 'Repeticiones',
          },
          categories: []  // This should contain the dates of each record.
        },
        yaxis: {
          title: {
            text: 'Peso (kg)',
          },
        },
        tooltip: {
          mode: 'light',
        },
        colors: ['#2196f3'],
        grid: {
          borderColor: '#ffffff30'
        }
      },
      chartSeries: [
        {
          name: 'Peso',
          data: []  // This should contain the max weight lifted for each record.
        }
      ],
      chartTimeSeriesOptions: {
        /* dataLabels: {
          enabled: true,
        }, */
        colors: ['#2196f3', '#6ee9ff'],
        stroke: {
          curve: 'smooth',
          width: 4
        },
        title: {
          text: '',
          align: 'center',
          style: {
            fontSize: '16px',
          }
        },
        chart: {
          foreColor: '#ccc',
          width: '100%',
          toolbar: {
            show: false,
          }
        },
        xaxis: {
          title: {
            text: '',
          },
          categories: []  // This should contain the dates of each record.
        },
        yaxis: {
          title: {
            text: 'Tiempo (seg)',
          },
        },

        tooltip: {
          theme: 'light'
        },
        grid: {
          borderColor: '#ffffff30'
        }
      },
      chartTimeSeries: [
        {
          name: 'Valor',
          data: [],
        }
      ],
    };
  },
  computed: {
    returnHeight() {
      let isMobile = this.$vuetify.breakpoint.mobile;
      let height = this.$vuetify.breakpoint.height;

      if (height < 667) {
        return 140
      }
      if (height < 736) {
        return 160
      }
      if (height < 812) {
        return 180
      }
      if (height < 1024) {
        return 200
      }
    },
    filteredExercises() {
      if (!this.searchQuery) {
        return this.isNew ? this.filterNewExercises(this.allExercises) : this.allExercises;
      }
      return this.isNew
        ? this.filterNewExercises(
          this.allExercises.filter(exercise =>
            exercise.name.toLowerCase().includes(this.searchQuery.toLowerCase())
          )
        )
        : this.allExercises.filter(exercise =>
          exercise.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        );
    },
  },

  created() {
  },
  methods: {
    addNewShow() {
      this.isNew = false;
      this.update = false;
      this.show = true;
    },
    returnMomentFormat(date) {
      if (date && typeof date.toDate !== 'undefined') {
        return moment(date.toDate()).locale('es').fromNow(true)
      } else {
        return moment().locale('es').fromNow(true)
      }

    },
    openNew() {
      this.isNew = true;
      this.exercise.name = false;
      this.disableTypeButton = true;
      this.update = false;
      this.show = true

    },
    updateExerciseData() {
      this.exercise.currentWeight = null;
      this.exercise.repAmount = null;
      this.exercise.velocity = null;
      this.exercise.time = null;
      this.exercise.distance = null;
      this.exercise.bestTime = null;

      let exercise = this.allExercisesList.find(exercise => exercise.name == this.exercise.name);

      if (!exercise) {
        exercise = this.allExercisesList.find(exercise => exercise == this.exercise.name);
      }
      if (!exercise.type) {
        this.exercise.type = 0;
      } else {
        this.exercise.type = parseInt(exercise.type)
      }



    },
    filterNewExercises(exercises) {
      return exercises.filter(exercise => !this.exerciseList.includes(exercise.name));
    },
    showExercise(exercise) {
      this.exercise.name = exercise.name;
      this.exercise.type = parseInt(exercise.type);
      this.picker = true;
    },
    async loadExerciseList() {
      this.loading2 = true;
      let user_id;

      if (this.$props.user) {
        user_id = this.$props.user.id;
      } else {
        user_id = this.$store.state.Auth.token.claims.user_id;
      }

      const docRef = collection(db, `users/${user_id}/exercises`);
      const docSnap = await getDocs(docRef);

      if (docSnap.empty) {
        //"No such document!");
      } else {
        let list = [];
        docSnap.docs.map(doc => {
          list.push({
            name: doc.id,
            lastUpdateDate: doc.data().lastUpdateDate,
            type: doc.data().type
          })
        });

        this.exerciseList = list;

        //sort by last update date
        this.exerciseList = this.exerciseList.sort(function (a, b) {
          return new Date(b.lastUpdateDate.seconds) - new Date(a.lastUpdateDate.seconds);
        });

      }

      this.availableExercises = this.allExercisesList.filter(exercise => {
        // If exercise is a string, use it directly, otherwise use the name property
        const exerciseName = typeof exercise === 'string' ? exercise : exercise.name;

        // Check if exerciseList includes this name
        return !this.exerciseList.map(e => e.name).includes(exerciseName);
      });

      this.loading2 = false;

    },
    formatDate(date) {
      return date.format("DD/MM/YYYY");
    },
    formatServerTimestamp(timestamp) {
      //format it to only output the day and month name abreation

      const date = moment(timestamp).locale('es').format("DD") + " " + moment(timestamp).locale('es').format("MMM");

      return date;
    },

    sliderColor(value) {
      if (value === 1) return 'green lighten-2';
      else if (value === 2) return 'green lighten-1';
      else if (value === 3) return 'green ';
      else if (value === 4) return 'green darken-1';
      else if (value == 5) return 'green darken-2';
      else if (value === 6) return 'yellow darken-2';
      else if (value === 7) return 'orange';
      else if (value === 8) return 'orange darken-3';
      else if (value === 9) return 'red';
      else if (value === 10) return 'red darken-3';
    },
    validate() {
      this.$refs.form.validate()
    },
    async saveExerciseData() {
      this.validate();

      if (this.isNew && (this.exercise.name == null || this.exercise.name == "")) {
        this.$notify({
          group: "feedback",
          title: "Falta información",
          text: "Por favor seleccione un ejercicio",
          type: "warning",
        });
        return;
      }


      if (this.exercise.type == 0) {
        if (!this.exercise.currentWeight) {
          this.$notify({
            group: "feedback",
            title: "Falta información",
            text: "Por favor ingrese un peso valido",
            type: "warning",
          });
          return;
        }

        if (!this.exercise.repAmount) {
          this.$notify({
            group: "feedback",
            title: "Falta información",
            text: "Por favor ingrese una cantidad de repeticiones",
            type: "warning",
          });
          return;
        }

      } else if (this.exercise.type == 1) {
        if (!this.exercise.velocity) {
          this.$notify({
            group: "feedback",
            title: "Falta información",
            text: "Por favor ingrese una velocidad valida",
            type: "warning",
          });
          return;
        }
      } else if (this.exercise.type == 2 || this.exercise.type == 5) {
        if (!this.exercise.time) {
          this.$notify({
            group: "feedback",
            title: "Falta información",
            text: "Por favor ingrese un tiempo valido",
            type: "warning",
          });
          return;
        }

        if (this.exercise.type == 5 && !this.exercise.bestTime) {
          this.$notify({
            group: "feedback",
            title: "Falta información",
            text: "Por favor ingrese un mejor tiempo valido",
            type: "warning",
          });
          return;
        }
      } else if (this.exercise.type == 3) {
        if (!this.exercise.distance) {
          this.$notify({
            group: "feedback",
            title: "Falta información",
            text: "Por favor ingrese una distancia valida",
            type: "warning",
          });
          return;
        }
      }



      if (!this.exercise.rpe) {
        this.$notify({
          group: "feedback",
          title: "Falta información",
          text: "Por favor ingrese un RPE valido",
          type: "warning",
        });
        return;
      }

      this.loading = true;
      let user_id;

      if (this.$props.user) {
        user_id = this.$props.user.id;
      } else {
        user_id = this.$store.state.Auth.token.claims.user_id;
      }

      const timestamp = Timestamp.fromDate(new Date());
      let exercise = this.exercise;

      let objAdd = {
        date: timestamp,
        rpe: parseFloat(exercise.rpe),
      }

      if (exercise.type == 0) {
        objAdd.weight = parseFloat(exercise.currentWeight);
        objAdd.repAmount = parseInt(exercise.repAmount);
      } else if (exercise.type == 1) {
        objAdd.velocity = parseFloat(exercise.velocity);
      } else if (exercise.type == 2 || exercise.type == 5) {
        objAdd.time = parseFloat(exercise.time);

        if (exercise.type == 5) {
          objAdd.bestTime = parseFloat(exercise.bestTime);
        }

      } else if (exercise.type == 3) {
        objAdd.distance = parseFloat(exercise.distance);
      }
      else if (exercise.type == 4) {
        objAdd.weight = parseFloat(exercise.currentWeight);

      }



      const exerciseDoc = doc(db, `users/${user_id}/exercises`, exercise.name);
      await setDoc(exerciseDoc, {
        name: exercise.name,
        lastUpdateDate: timestamp,
        type: exercise.type || 0
      });

      const entriesCollection = collection(db, `users/${user_id}/exercises/${exercise.name}/entries`);
      await addDoc(entriesCollection, objAdd);


      this.$notify({
        group: "feedback",
        title: "Carga guardada",
        text: "Nuevo registro guardado con éxito.",
        type: "success",
      });
      if (this.isNew) {
        this.exerciseList.push({ ...exercise });
        this.availableExercises = this.allExercisesList.filter(exercise => {
          // If exercise is a string, use it directly, otherwise use the name property
          const exerciseName = typeof exercise === 'string' ? exercise : exercise.name;

          // Check if exerciseList includes this name
          return !this.exerciseList.map(e => e.name).includes(exerciseName);
        });
      }else{  
        this.exerciseList = this.exerciseList.map((item) => {
          if (item.name === exercise.name) {
            return { ...item, lastUpdateDate: timestamp };
          }
          return item;
        });
      }

       this.exerciseList = this.exerciseList.sort(function (a, b) {
          return new Date(b.lastUpdateDate.seconds) - new Date(a.lastUpdateDate.seconds);
        });


      this.selectedExercises = [];
      this.selectedExerciseNames = [];
      this.loading = false;
      this.show = false;
    }
    ,

    handleSelectedExerciseNamesChange() {
      this.selectedExercises = this.selectedExerciseNames.map((name) => {
        const existingExercise = this.allExercises.find(
          (exercise) => exercise.name === name
        );
        if (!existingExercise) {
          //this.addExerciseToList(name);
        }
        return existingExercise || { name: name, currentWeight: "", repAmount: "", rpe: "", velocity: "" };
      });
    },

    async addExerciseToList(name) {
      let user_id;

      if (this.$props.user) {
        user_id = this.$props.user.id;
      } else {
        user_id = this.$store.state.Auth.token.claims.user_id;
      }
      const exerciseListRef = collection(db, `users/${user_id}/exercisesList`);
      await addDoc(exerciseListRef, {}, { id: name });
    }
    ,

    returnKey() {
      let key = uuidv4();
      return key
    },
    async loadExerciseHistory() {
  try {
    this.loading = true;

    // Determine user_id
    const user_id = this.$props.user 
      ? this.$props.user.id 
      : this.$store.state.Auth.token.claims.user_id;

    // Initialize variables
    const exercisesRef = collection(db, `users/${user_id}/exercises`);
    const exerciseName = this.exercise.name;
    const entriesRef = collection(db, `users/${user_id}/exercises/${exerciseName}/entries`);
    const entriesSnap = await getDocs(entriesRef);

    const exercise = {
      name: exerciseName,
      history: [],
    };

    // Populate exercise history
    entriesSnap.docs.forEach(doc => {
      const data = doc.data();
      exercise.history.push({
        date: moment(new Date(data.date.seconds * 1000)),
        weight: data.weight,
        repAmount: data.repAmount,
        rpe: data.rpe,
        velocity: data.velocity,
        time: data.time,
        bestTime: data.bestTime,
        distance: data.distance,
        id: doc.id,
        type: parseInt(this.exercise.type),
      });
    });

    // Sort history by date descending
    exercise.history.sort((a, b) => b.date - a.date);
    
    this.allExercises.push(exercise);

    if (this.allExercises.length === 0) {
      this.showNoDataWarning();
    } else {
      this.updateCharts(exercise);
      this.updateWeightsByRep(exercise);
      this.updateLineChartsByRepRange(exercise);
    }

    this.loading = false;

    if (this.update) {
      this.show = false;
    }

  } catch (error) {
    this.loading = false;
    this.handleError(error);
  }
},

showNoDataWarning() {
  this.$notify({
    group: "feedback",
    title: "No hay datos",
    text: "No hay datos para mostrar",
    type: "warning",
  });
},

updateCharts(exercise) {

  let exerciseCopy = JSON.parse(JSON.stringify(exercise));
  this.chartTimeSeries[0].data = [];
  this.chartTimeSeriesOptions.title.text = "Historico";

  // sort by date, date is a moment object
  exercise.history.sort((a, b) => a.date - b.date);

  this.chartTimeSeriesOptions.xaxis.categories =  exercise.history.map(record => record.date.locale('es').format("DD MMM"));

  switch (this.exercise.type) {
    case 1:
      this.updateVelocityChart(exercise);
      break;
    case 2:
    case 5:
      this.updateTimeChart(exercise);
      break;
    case 3:
      this.updateDistanceChart(exercise);
      break;
    case 4:
      this.updateWeightChart(exercise);
      break;
  }
},

updateVelocityChart(exercise) {
  this.chartTimeSeriesOptions.yaxis.title.text = "Velocidad (m/s)";
  this.chartTimeSeries[0].data = exercise.history.map(record => record.velocity);
},

updateTimeChart(exercise) {
  this.chartTimeSeriesOptions.yaxis.title.text = "Tiempo (seg)";
  this.chartTimeSeries[0].data = exercise.history.map(record => record.time);

  if (this.exercise.type === 5) {
    this.chartTimeSeries[0].name = 'Tiempo promedio';
    this.chartTimeSeries.push({
      name: 'Mejor tiempo',
      data: exercise.history.map(record => record.bestTime),
    });
  }
},

updateDistanceChart(exercise) {
  this.chartTimeSeriesOptions.yaxis.title.text = "Distancia (cm)";
  this.chartTimeSeries[0].data = exercise.history.map(record => record.distance);
},

updateWeightChart(exercise) {
  this.chartTimeSeriesOptions.yaxis.title.text = "Peso (kg)";
  this.chartTimeSeries[0].data = exercise.history.map(record => record.weight);
},

updateWeightsByRep(exercise) {
  let weightsByRep = {};
  let weightsByRepObjArr = {};

  exercise.history.forEach(record => {
    let rep = record.repAmount;
    let weight = record.weight;

    if (!weightsByRep[rep]) {
      weightsByRep[rep] = [];
      weightsByRepObjArr[rep] = [];
    }

    weightsByRep[rep].push(weight);
    weightsByRepObjArr[rep].push(record);
  });

  this.weightsByRepObjArr = weightsByRepObjArr;

  const maxWeightsByRep = this.calculateMaxWeightsByRep(weightsByRep);

  const records = Object.keys(maxWeightsByRep).map(rep => ({
    rep,
    maxWeight: maxWeightsByRep[rep],
  }));

  this.chartOptions.title.text = "Max KG x Rango de repeticiones";
  this.chartOptions.xaxis.categories = records.map(record => record.rep);
  this.chartSeries[0].data = records.map(record => record.maxWeight);
},

calculateMaxWeightsByRep(weightsByRep) {
  let maxWeightsByRep = {};
  for (let rep in weightsByRep) {
    let weights = weightsByRep[rep];
    maxWeightsByRep[rep] = Math.max(...weights);
  }
  return maxWeightsByRep;
},

handleError(error) {
  console.error("Error loading exercise history:", error);
  this.$notify({
    group: "feedback",
    title: "Error",
    text: "Failed to load exercise history",
    type: "error",
  });
}
,


    showHistory() {
      this.isNew = false;
      this.allExercises = [];
      this.history = true;
      this.loadExerciseHistory();

    },
    checkVelocityValues() {
      let history = this.allExercises[0].history;
      let everyVelocityValues = history.some(record => record.velocity);
      if (everyVelocityValues) {
        return true;
      } else {
        return false;
      }
    },
    checkCurrentWeightValues() {
      let history = this.allExercises[0].history;
      let everyCurrentWeightValues = history.some(record => record.weight);
      if (everyCurrentWeightValues) {
        return true;
      } else {
        return false;
      }

    },
    checkTimeValues() {
      let history = this.allExercises[0].history;
      let everyTimeValues = history.some(record => record.time);
      if (everyTimeValues) {
        return true;
      } else {
        return false;
      }

    },
    checkDistanceValues() {
      let history = this.allExercises[0].history;
      let everyDistanceValues = history.some(record => record.distance);
      if (everyDistanceValues) {
        return true;
      } else {
        return false;
      }

    },
    addExercise(name) {
      this.availableExercises.push(name);
      this.exercise.name = name;
      this.disableTypeButton = false;
    },
    calculateRMs(weight, rm) {
      // calculate e^(-0.0125 * rm)
      const eFactor = Math.exp(-0.0125 * rm);

      // calculate 1RM using Wathan's formula
      const oneRM = weight / (1.7122 - 0.2548 * eFactor);

      // create an object to hold all RMs
      let allRMs = {};

      // calculate other RMs from 1 to 20, excluding the provided RM
      for (let reps = 1; reps <= 20; reps++) {
        if (reps !== rm) {
          // calculate e^(-0.0125 * reps)
          eFactor = Math.exp(-0.0125 * reps);

          // calculate the weight for this RM using rearranged Wathan's formula
          const rmWeight = oneRM * (1.7122 - 0.2548 * eFactor);

          // add this RM to the object
          allRMs[reps] = rmWeight;
        }
      }

      // return the object with all RMs
      return allRMs;
    },
    async deleteExercise() {
      try {
        let confirm = await this.$confirm(
          "Seguro que quieres eliminar este ejercicio y toda su información?",
          {
            color: "error",
            title: "Borrar Datos",
            icon: "mdi-alert-circle",
            buttonTrueText: "Si",

          }
        );
        if (!confirm) return;

        const db = getFirestore();
        let user_id;

        if (this.$props.user) {
          user_id = this.$props.user.id;
        } else {
          user_id = this.$store.state.Auth.token.claims.user_id;
        }
        const exerciseDoc = doc(db, `users/${user_id}/exercises`, this.exercise.name);
        deleteDoc(exerciseDoc);
        this.$notify({
          group: "feedback",
          title: "Ejercicio eliminado",
          text: "Ejercicio eliminado con éxito.",
          type: "success",
        });
        this.history = false;
        this.exerciseList = this.exerciseList.filter(exercise => exercise.name != this.exercise.name);
        this.availableExercises = this.allExercisesList.filter(exercise => {
          // If exercise is a string, use it directly, otherwise use the name property
          const exerciseName = typeof exercise === 'string' ? exercise : exercise.name;

          // Check if exerciseList includes this name
          return !this.exerciseList.map(e => e.name).includes(exerciseName);
        });
        this.exercise.name = null;

        this.history = false;
        this.show = false;
        this.picker = false;
      }
      catch (err) {
        this.$notify({
          group: "feedback",
          title: "Error",
          text: "Error al eliminar el ejercicio.",
          type: "error",
        });
      } finally {
        this.loading = false;
      }
    },
    rowClick(entry) {
      this.exercise.currentWeight = entry.weight;
      this.exercise.repAmount = entry.repAmount;
      this.exercise.rpe = entry.rpe;
      this.exercise.velocity = entry.velocity;
      this.exercise.time = entry.time;
      this.exercise.bestTime = entry.bestTime;
      this.exercise.distance = entry.distance;
      this.exercise.date = entry.date;
      this.exercise.type = parseInt(entry.type);
      this.show = true;
      this.update = entry.id;
    },
    async updateExerciseEntry() {
      this.loading = true;
      const db = getFirestore();
      let user_id;

      if (this.$props.user) {
        user_id = this.$props.user.id;
      } else {
        user_id = this.$store.state.Auth.token.claims.user_id;
      }

      let entryDocId = this.update;

      const entryDoc = doc(db, `users/${user_id}/exercises/${this.exercise.name}/entries`, entryDocId);

      let updateObj = {
        rpe: parseFloat(this.exercise.rpe),
      }

      if (this.exercise.type == 0) {
        updateObj.weight = parseFloat(this.exercise.currentWeight);
        updateObj.repAmount = parseInt(this.exercise.repAmount);
      } else if (this.exercise.type == 1) {
        updateObj.velocity = parseFloat(this.exercise.velocity);
      } else if (this.exercise.type == 2 || this.exercise.type == 5) {
        updateObj.time = parseFloat(this.exercise.time);
        if (this.exercise.type == 5) {
          updateObj.bestTime = parseFloat(this.exercise.bestTime);
        }
      } else if (this.exercise.type == 3) {
        updateObj.distance = parseFloat(this.exercise.distance);
      }
      else if (this.exercise.type == 4) {
        updateObj.weight = parseFloat(this.exercise.currentWeight);
      }

      await updateDoc(entryDoc, updateObj);

      this.$notify({
        group: "feedback",
        title: "Carga guardada",
        text: "Registro actualizado con éxito.",
        type: "success",
      });
      this.showHistory();
      this.show = false;


    },
    async deleteExerciseEntry() {

      try {
        let confirm = await this.$confirm(
          "Seguro que quieres eliminar este registro?",
          {
            color: "error",
            title: "Borrar Registro",
            icon: "mdi-alert-circle",
            buttonTrueText: "Si"
          }
        )

        if (!confirm) return;
        this.loading = true;
        const db = getFirestore();
        let user_id;

        if (this.$props.user) {
          user_id = this.$props.user.id;
        } else {
          user_id = this.$store.state.Auth.token.claims.user_id;
        }

        let entryDocId = this.update;

        const entryDoc = doc(db, `users/${user_id}/exercises/${this.exercise.name}/entries`, entryDocId);
        await deleteDoc(entryDoc);
        this.$notify({
          group: "feedback",
          title: "Registro eliminado",
          text: "Registro eliminado con éxito.",
          type: "success",
        });
        this.showHistory();

      }
      catch (err) {
        this.$notify({
          group: "feedback",
          title: "Error",
          text: "Error al eliminar el registro.",
          type: "error",
        });
      } finally {
        this.loading = false;
      }

    },
    returnColor(type) {
      if (type == 0) {
        return "primary darken-1";
      } else if (type == 1) {
        return "#d9e2e6";
      } else if (type == 2 || type == 5) {
        return "#ff6f61";
      } else if (type == 3) {
        return "#5fdab8";
      } else if (type == 4) {
        return "red";
      } else {
        return "#064f8c";
      }

    },
   updateLineChartsByRepRange(exercise) {
    // Clear any existing data in the series array
    this.repRangeProgressSeries = [];

    // Iterate over each rep range
    for (let rep in this.weightsByRepObjArr) {
      // Filter the history for the current rep range
      let repHistory = this.weightsByRepObjArr[rep];
      // Sort the history by date to ensure the chart is correctly ordered
      repHistory.sort((a, b) => a.date - b.date);

      // Prepare the data points for the current rep range
      let seriesData = repHistory.map(record => ({
        x: record.date,  // Date will be used for x-axis (time)
        y: record.weight,  // Weight will be used for y-axis (progress)
      }));

      // Push a new series for each rep range into the chart series array
      this.repRangeProgressSeries.push({
        name: `${rep} RM`,  // Each series will have a unique name
        data: seriesData,  // The data points for this rep range
      });
    }
  },


  },
  

};


</script>

<style scoped>
#tableLogs {
  overflow-x: hidden !important;
}

.slide-up {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  overflow: hidden;
  transition: height 0.3s ease-out;
  background: #eee;
}

.slide-up.show {
  height: 50vh;
  /* Adjust this to control how much of the div is shown */
}

* {
  touch-action: manipulation;
}
</style>

<style>
.apexcharts-tooltip.apexcharts-theme-light .apexcharts-tooltip-title {
  background-color: transparent !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background: #000 !important;
  color: #fff !important;
  border-color: var(--v-primary-base) !important;
}


.v-autocomplete__content {
  background: var(--v-primary-darken4) !important;
}

#checktab.hide-tab .v-item-group.v-tabs-bar {
  display: none !important;
}
</style>